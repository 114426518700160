import Project from './Project';
import { ReactElement } from 'react';
import {  Image, Code,  Link, Box, SimpleGrid, Icon, Text, Stack, Flex, Heading } from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit, FcOk } from 'react-icons/fc';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useColorModeValue,
    useColorMode
  } from '@chakra-ui/react'
import Emoji from './Emoji';
import { ExternalLinkIcon } from '@chakra-ui/icons'
import ImageComponent from './ImageComponent';


const Feature = ({ url, title, text, icon, lnk, linkcolor, l1, l2, l3}: FeatureProps) => {
  const { toggleColorMode } = useColorMode()
  const color = useColorModeValue('black', 'white');
  return (
    <Stack >
    <Stack direction='row'>
        <Text color={color} fontWeight={600}>
          <Link isExternal bg={linkcolor} href={lnk}> {title} <ExternalLinkIcon mx='1px' />
           </Link>
           </Text>
        <Link href={lnk}>
      {<Image
              height={'5vh'}
              mb={'2'}
              maxW={"70%"}
              alt={'Login Image'}
              objectFit={'contain'}
              src={
                  url
              }
        /> } 
        </Link>
      </Stack>
      <Text color={color}>{text}</Text>

      <Stack direction='row'>
      <Text fontSize={14}>STACK: </Text> 
      <Code children={l1} />
      <Code children={l2}/>
      <Code children={l3} />
    </Stack>
    </Stack>
  );
};

export default function Projects() {
  return (
    <Box id="Projects"
    margin={{ base: 2, md: 10 }}
    pr={{ base: 6, md: 100 }}
    pl={{ base: 6, md: 100 }}
    pb={10} >
        
        <Heading fontSize={{ sm: '1xl', md: '2xl', lg: '2xl' }}
        paddingTop={9}
        paddingBottom={7}
        >projects <Emoji symbol="🛠"/> </Heading>
        
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          
            <Feature
            icon={<Icon as={FcAssistant} w={10} h={10} />}
            title={'cryptaid'}
            text={
                'Web 3.0 Website that allows users to donate with anonymity and safety  to organizations using the concept of decentralized apps.'
            }
            lnk={"https://cryptaid-theta.vercel.app/"}
            //linkcolor={"red.200"}
            l1={"Solidity"}
            l2={"React"}
            l3={"Moralis SDK"}
            url={require('./images/cryptaid.png')}
          />  
                  
        <Feature
          icon={<Icon as={FcOk} w={10} h={10} />}
          title={'30Seconds'}
          text={
            'Website that offers Behavioral Interview Practice. Uses a speech-to-text API to offer feedback of self-recorded interviews. '
          }
          lnk={"https://seconds-ef259.web.app/"}
          //linkcolor={"green.200"}
          l1={"HTML/CSS"}
          l2={"React"}
          l3={"AssemblyAI API"}
          url={require('./images/30s.png')}
        />
       
        <Feature
          icon={<Icon as={FcInTransit} w={10} h={10} />}
          title={'Fiesta'}
          text={
            'Social platform that enables users to find & add parties to a map, rate parties, add reviews and more!'
          }
          lnk={"https://fiesta-5caa1.web.app/"}
          //linkcolor={"blue.200"}
          l1={"Firebase"}
          l2={"React"}
          l3={"Google Maps API"}
          url={require('./images/fiesta.png')}
        />
        <Feature
          icon={<Icon as={FcInTransit} w={10} h={10} />}
          title={'InstaAid'}
          text={
            'Health app that connects people with friends/family, if they are in need of an epipen/inhaler in the case of an emergency'
          }
          lnk={"https://devpost.com/software/instaaid-3rmdzf"}
          //linkcolor={"yellow.200"}
          l2={"Node.js"}
          l1={"Swift/Xcode"}
          l3={"Sketch"}
          url={require('./images/instaaid.png')}
        />
      </SimpleGrid>
    </Box>
  );
}
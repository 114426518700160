import React from 'react';
import {
  ChakraProvider,
  Box,
  useColorModeValue,
  Container,
  Stack,
  extendTheme
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Navbar from './Navbar';
import Projects from './Projects';
import Me from './AboutMe';
import Skills from './Skills';
import Emoji from './Emoji';
import { Analytics } from '@vercel/analytics/react';
import MyIntro from './MyIntro';

// If you want to customize the theme, you can use extendTheme
const customTheme = extendTheme({
  fonts: {
    heading: "'Open Sans', sans-serif",
    body: "'Open Sans', sans-serif"
  },
});

const MotionBox = motion(Box);

function App({ Component, pageProps }) {
  return (
    <>
      <ChakraProvider >
        <MotionBox
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          borderWidth={0.1}
        >
          {/* <Navbar /> */}
          <Box
            paddingTop={{ base: "20px", sm: "0px", md: "0px", lg: "0px", xl: "0px" }}
            paddingLeft={{ sm: "20px", md: "30px", lg: "50px" }}
            paddingRight={{ sm: "20px", md: "30px", lg: "50px" }}
          >
            <br></br>
            <br></br>
            <MyIntro />
            <Me />
            <Projects />
            {/* <Skills /> */}
          </Box>
          <br></br>
          <Box
            bg={useColorModeValue('white.50', 'white.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
          >
            <Container
              as={Stack}
              maxW={'6xl'}
              py={4}
              direction={{ base: 'column', md: 'row' }}
              spacing={4}
              justify={{ base: 'center', md: 'center' }}
              align={{ base: 'center', md: 'center' }}
            >
              {/* <Text fontSize={'sm'}>created by Jacey Buchner w/ <Emoji symbol="🍵" />  and <Emoji symbol="❤️" /></Text> */}
            </Container>
          </Box>
        </MotionBox>
      </ChakraProvider>
      <Analytics />
    </>
  );
}

export default App;

import React from 'react';

function ImageComponent({ imageSrc, altText }) {
  return (
    <div>
      <img src={imageSrc} alt={altText} />
    </div>
  );
}

export default ImageComponent;
import Project from './Project';
import { Link, Box, SimpleGrid, Icon, Text, Stack, Flex, Heading, Spacer } from '@chakra-ui/react';
import { useColorMode, useColorModeValue } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons'


const Highlighted = ({ text = "", highlight = "" }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
  
    return (
      <span>
        {parts.filter(String).map((part, i) => {
          return regex.test(part) ? (
            <mark key={i}>{part}</mark>
          ) : (
            <span key={i}>{part}</span>
          );
        })}
      </span>
    );
  };

export default function Me() {
  const { toggleColorMode } = useColorMode()
  const color = useColorModeValue('black', 'white');


  return (
    <Box 
    
    id="About" 
    margin={{ base: 2, md: 10 }}
    pr={{ base: 6, md: 100 }}
    pl={{ base: 6, md: 100 }}>

        <Heading fontSize={{ sm: '1xl', md: '2xl', lg: '2xl' }}
        paddingTop={{base:7, sm: 6, md: 3, lg: 3}}
        paddingBottom={5}
         > 
         about me: </Heading>

         <Flex>
          
            <Text color={color} fontSize={{ base: '18px'}}>
                I'm a fourth-year Computer Science student at UC Santa Barbara and am currently a SWE Intern for  
                <Text color ='blue' as ='b'> G</Text>
                <Text color ='red' as ='b'>o</Text>
                <Text color ='yellow.400' as ='b'>o</Text>
                <Text color ='blue' as ='b'>g</Text>
                <Text color ='green' as ='b'>l</Text>
                <Text color ='red' as ='b'>e </Text>
                Cloud. 
                (Last summer, I interned for Google Display Ads)
                  
                <br></br>
                <br></br>
                
                At UCSB, I am a peer mentor, a part of the   <></>
                <Link 
                 href="https://inspire.engineering.ucsb.edu/meet-team" isExternal 
                 color="blue"
                _hover={{ color: "black" }}
                >Inspire Program <ExternalLinkIcon mx='2px' />. </Link> <></>
                
                Previously I was an undergraduate researcher for the <></>
                <Link 
                 href="https://seclab.cs.ucsb.edu/" isExternal 
                 color="blue"
                _hover={{ color: "black" }}
                >Security Lab <ExternalLinkIcon mx='2px' />.
                </Link> <></>

                Aside from that I also helped manage and operate <></>
                <Link href="https://blockchainucsb.com/" isExternal 
                 color="blue"
                _hover={{ color: "black" }}
                >Blockchain @ UCSB <ExternalLinkIcon mx='2px' />. <></>
                </Link> 
                I have also worked as a Product Management Intern at  
                <Link 
                 href="https://healthy.kaiserpermanente.org/northern-california/front-door" isExternal 
                 color="blue"
                _hover={{ color: "black" }}
                > Kaiser Permanente<ExternalLinkIcon mx='2px' />
                </Link> <></>

                <br></br>
                <br></br>
                I love meeting new people, so if you have any questions feel free to reach out!

                 {/* Over the past year, I have also been involved in research at UCSB, as part of 
                the 
                <Link href="https://seclab.cs.ucsb.edu/" isExternal color={"gray.500"}
                _hover={{ bg: "blue.500", color: "white" }}
                >Security Lab</Link>
                , under the guidance
                of Prof. Kruegel. */}

                {/* My team and I worked on the project: "Finding Scam Contracts on the Ethereum Blockchain" and will be presenting at the 
                <Link href="https://ersp.eng.ucsd.edu/about/2023-ersp-national-conference-virtual" isExternal 
                _hover={{ bg: "blue.500", color: "white" }}
                color={"blue.600"}> National ERSP Conference. </Link>
                <br></br>
                <br></br> */}

                {/* <br></br>
                <br></br>
                Last summer ('22) I worked at <Link href="https://healthy.kaiserpermanente.org/northern-california/front-door" 
                _hover={{ bg: "blue.500", color: "white" }}
                color={"blue.600"}>Kaiser Permanente</Link> as a Product Management Intern
                where I helped improve their chatbot technology. */}
                
            </Text>  
          </Flex>
          <br></br> 
{/* 
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
            <Box minWidth={"150"}pr={'5'}><Text fontWeight={'bold'}>next: </Text></Box>
          
            <Text fontSize={{ base: 'lg', lg: 'xl' }} color={'gray.700'}>
                I will be interning at 
                <Link href="https://seclab.cs.ucsb.edu/" isExternal color={"blue.600"}> Google </Link>
                for the summer of 23!
                 
                
            </Text>  
          </Flex> */}

          {/* <br></br>

          <Flex flexDirection={{ base: 'column', md: 'row' }}>
            <Box minWidth={"150"}pr={'5'}><Text fontWeight={'bold'}>now: </Text></Box>
          
            <Text fontSize={{ base: 'lg', lg: 'xl' }} color={'gray.700'}>
                I am currently working on the project: "Finding Scam Contracts on the Ethereum Blockchain" under the guidance of Professors Giovanni Vigna 
                and Christopher Kruegel in the  
                 <Link href="https://seclab.cs.ucsb.edu/" isExternal color={"blue.600"}> SecLab. </Link>
                 I am also helping grow the
                <Link href="https://blockchainucsb.com/" isExternal color={"blue.600"}> Blockchain @ UCSB </Link>
                club as the Vice President.
            </Text>  
          </Flex>
          <br></br> */}

          {/* <Flex flexDirection={{ base: 'column', md: 'row' }}>
          <Box minWidth={"150"}pr={'5'}><Text fontWeight={'bold'}>before: </Text></Box>

            <Text fontSize={{ base: 'lg', lg: 'xl' }} color={'gray.700'}>
            This past summer I worked at <Link href="https://healthy.kaiserpermanente.org/northern-california/front-door" color={"blue.600"}>Kaiser Permanente</Link> as a Product Management Intern
            where I helped improve their chatbot technology.
            <br></br><br></br>
            Prior to working at Kaiser Permanente, I worked at <Link color={"blue.600"} href="https://www.berkeleypharmatech.com/"> Berkeley Pharma Tech</Link> as a Software Engineer Intern, where my team and I
            used a javascript art engine to create a collection of 10,000 Non-fungible-tokens to fund research into fast-growing redwood trees.
            <br></br><br></br>
            </Text>  
          </Flex> */}

          {/* <br></br>
          <Flex flexDirection={{ base: 'column', md: 'row' }}>
            <Box minWidth={"150"}pr={'5'}><Text pr={'5'}fontWeight={'bold'}>interests: </Text></Box>
            <Text fontSize={{ base: 'lg', lg: 'xl' }} color={'gray.700'}>
             I'm interested in learning more about Distributed Systems and Blockchain technologies, 
            Autonomous Driving and Security in the future! 
            </Text>
          </Flex> */}

          {/* <br></br>

          <Flex flexDirection={{ base: 'column', md: 'row' }}>
          <Box minWidth={"150"}pr={'5'}><Text pr={'5'}fontWeight={'bold'}>courses: </Text> </Box>
            <Text fontSize={{ base: 'lg', lg: 'xl' }} color={'gray.700'}>
            Obj. Oriented Design in C++, Discrete Structures, Differential Equations and Linear Algebra, Computer Architecture.
            </Text>
          </Flex> */}
        
    </Box>
  );
}
import Project from './Project';
import { ReactElement } from 'react';
import { Image, Link, useBreakpointValue, Box, SimpleGrid, Icon, Text, HStack,Stack, Flex, Heading, Spacer, Button } from '@chakra-ui/react';
import { FcAssistant, FcDonate, FcInTransit } from 'react-icons/fc';
import Highlighter from "react-highlight-words";
import Emoji from './Emoji';
import { useColorMode, useColorModeValue} from '@chakra-ui/react';


const Highlighted = ({ text = "", highlight = "" }) => {
    if (!highlight.trim()) {
      return <span>{text}</span>;
    }
    const regex = new RegExp(`(${highlight})`, "gi");
    const parts = text.split(regex);
  
    return (
      <span>
        {parts.filter(String).map((part, i) => {
          return regex.test(part) ? (
            <mark key={i}>{part}</mark>
          ) : (
            <span key={i}>{part}</span>
          );
        })}
      </span>
    );
  };

export default function MyIntro() {
  const { toggleColorMode } = useColorMode()
  const color = useColorModeValue('black', 'white');
  // add this to change color of website, then add color={color} in the react 

  const linked_in_white = require('./images/linkedin.svg').default;
  const linked_in_black = require('./images/linkedin_black.svg').default;
  const linkedInImage = useColorModeValue(linked_in_white, linked_in_black);

  const email_white = require('./images/email.svg').default;
  const email_black = require('./images/email_black.svg').default;
  const email = useColorModeValue(email_white, email_black);

  const github_white = require('./images/github_white.svg').default;
  const github_black = require('./images/github.svg').default;
  const github = useColorModeValue(github_black, github_white);

  const cv_white = require('./images/cv_white.svg').default;
  const cv_black = require('./images/cv.svg').default;
  const cv = useColorModeValue(cv_black, cv_white);

  return (
    <Box 
    id="MyIntro"
     margin={{base: 2, md: 10}} 
     paddingTop={{sm:40 ,md:50, lg:100}}
    pr={{ base: 6, md: 100 }}
    pl={{ base: 6, md: 100 }}>

        <Flex justify={'flex-start'} align={'center'}>

          <Stack spacing={2} w={'full'} maxW={'lg'}>

          <Heading size='3xl'>
          <Emoji symbol="👨🏻‍💻"></Emoji>
          </Heading>

            <Heading fontSize={{ base: '3xl', md: '3.5xl', lg: '4xl' }} > 
                <Text
                  fontSize={'100%'}
                  as={'span'}
                  position={'relative'}
                >
                  Hey, I'm Jacey.
                </Text>
            </Heading>

            <Text color={color} fontSize={{ base: 'medium', lg: 'xl' }} >
                I study CS @ UC Santa Barbara! <Emoji symbol="🌴"></Emoji>
             </Text>

            <HStack spacing={3}>
                <Link isExternal href="https://www.linkedin.com/in/jaceybuchner/">
                {<Image
                    height={'4vh'}
                    mb={'2'}
                    maxW={"90%"}
                    alt={'LinkedIn'}
                    objectFit={'contain'}
                    src={linkedInImage}
                /> } 
                </Link>

                {/* <Link isExternal href="https://github.com/jaceybuchner">
                {<Image
                    height={'4vh'}
                    mb={'2'}
                    maxW={"90%"}
                    alt={'Github'}
                    objectFit={'contain'}
                    src={github}
                /> } 
                </Link> */}

                <Link isExternal style={{textDecoration: 'none'}} href="mailto:jaceybuchner@gmail.com">
                {<Image
                    height={'4vh'}
                    mb={'2'}
                    maxW={"90%"}
                    alt={'Resume'}
                    objectFit={'contain'}
                    src={email}
                /> } 
                </Link>

                {/* <Link style={{textDecoration: 'none'}} href="/jacey_b_resume.pdf">
                {<Image
                    height={'4vh'}
                    mb={'2'}
                    maxW={"90%"}
                    alt={'Resume'}
                    objectFit={'contain'}
                    src={cv}
                /> } 
                </Link> */}

                {/* <Link isExternal style={{textDecoration: 'none'}} href="https://www.linkedin.com/in/jaceybuchner/">
                
                    <Button
                    rounded={'9'}
                    bg={'gray.200'}
                    color={'blackAlpha.700'}
                    _hover={{
                        bg: 'gray.500',
                        color: 'white',
                    }}>
                    LinkedIn
                    </Button>
                </Link> */}
                
                
                {/* <Link style={{textDecoration: 'none'}} href="/jacey_b_resume.pdf" >
                    <Button
                    rounded={'9'}
                    bg={'gray.200'}
                    color={'blackAlpha.700'}
                    _hover={{
                        bg: 'gray.500',
                        color: 'white',
                    }}>
                    Resume
                    </Button>
                </Link> */}

                {/* <Link isExternal style={{textDecoration: 'none'}} href="mailto:jaceybuchner@gmail.com">
                    <Button
                    rounded={'9'}
                    bg={'gray.200'}
                    color={'blackAlpha.700'}
                    _hover={{
                        bg: 'gray.500',
                        color: 'white',
                    }}>
                    Email
                    </Button> */}
                {/* </Link> */}
            </HStack>
          </Stack>

        
          {/* {<Image
                    height={'18vh'}
                    mb={'2'}
                    maxW={"100%"}
                    alt={'MyImage'}
                    objectFit={'contain'}
                    src={require('./images/centered-hs.png')}
                /> }  */}
           
          {/* </HStack> */}
        </Flex>
        
    </Box>
  );
}